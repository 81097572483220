// src/pages/SignUp/SignUp.jsx

import React, { useState } from 'react';
import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  Link,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { auth, db } from '../../firebaseConfig';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const theme = createTheme({
  typography: {
    fontFamily: '"Barlow", sans-serif',
    h5: {
      fontWeight: 800,
      fontSize: '32px',
      lineHeight: '40px',
      color: 'rgb(28, 37, 46)',
      textAlign: 'center',
      marginBottom: '8px',
    },
    body2: {
      fontSize: '1rem',
      color: 'rgba(28, 37, 46, 0.6)',
      textAlign: 'center',
      marginBottom: '32px',
    },
  },
});

const SignUp = () => {
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    showPassword: false,
    termsAccepted: false,
  });

  const [termsError, setTermsError] = useState(false);
  const [submissionError, setSubmissionError] = useState('');

  const navigate = useNavigate();

  const handleChange = (prop) => (event) => {
    if (prop === 'termsAccepted') {
      setFormValues({ ...formValues, [prop]: event.target.checked });
      if (termsError && event.target.checked) {
        setTermsError(false);
      }
    } else {
      setFormValues({ ...formValues, [prop]: event.target.value });
    }
  };

  const handleClickShowPassword = () => {
    setFormValues({
      ...formValues,
      showPassword: !formValues.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    if (!formValues.termsAccepted) {
      setTermsError(true);
      return;
    }

    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formValues.email,
        formValues.password
      );

      const user = userCredential.user;

      // Store additional user data in Firestore
      await setDoc(doc(db, 'users', user.uid), {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        email: formValues.email,
        premium: false, // Default to non-premium
      });

      // Redirect to premium page or home
      navigate('/premium');
    } catch (error) {
      console.error('Error signing up:', error.message);
      setSubmissionError(error.message);
      // Optionally, you can display the error to the user in the UI
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xs">
        <Box sx={{ mt: 8, mb: 32 }}>
          <Typography variant="h5">Get started absolutely free</Typography>
          <Typography variant="body2">
            Already have an account?{' '}
            <Link href="/signin" underline="hover">
              Sign in
            </Link>
          </Typography>
          <form onSubmit={handleSignUp} noValidate>
            <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
              <TextField
                label="First name"
                name="firstName"
                fullWidth
                variant="outlined"
                value={formValues.firstName}
                onChange={handleChange('firstName')}
                required
              />
              <TextField
                label="Last name"
                name="lastName"
                fullWidth
                variant="outlined"
                value={formValues.lastName}
                onChange={handleChange('lastName')}
                required
              />
            </Box>
            <TextField
              label="Email address"
              name="email"
              fullWidth
              margin="normal"
              variant="outlined"
              type="email"
              value={formValues.email}
              onChange={handleChange('email')}
              required
            />
            <FormControl variant="outlined" fullWidth margin="normal" required>
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={formValues.showPassword ? 'text' : 'password'}
                value={formValues.password}
                onChange={handleChange('password')}
                label="Password"
                placeholder="6+ characters"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {formValues.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl error={termsError} component="fieldset" variant="standard" sx={{ mt: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formValues.termsAccepted}
                    onChange={handleChange('termsAccepted')}
                    name="termsAccepted"
                    color="primary"
                  />
                }
                label={
                  <Typography variant="body2">
                    I agree to the{' '}
                    <Link href="/terms" underline="always">
                      Terms of Service
                    </Link>{' '}
                    and{' '}
                    <Link href="/privacy" underline="always">
                      Privacy Policy
                    </Link>
                    .
                  </Typography>
                }
              />
              {termsError && (
                <FormHelperText>You must agree to the terms and conditions.</FormHelperText>
              )}
            </FormControl>
            {submissionError && (
              <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                {submissionError}
              </Typography>
            )}
            <Button
              variant="contained"
              color="inherit"
              type="submit"
              fullWidth
              sx={{ mt: 3 }}
              disabled={!formValues.termsAccepted}
            >
              Create account
            </Button>
          </form>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default SignUp;
