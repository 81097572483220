// src/firebaseConfig.js

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions'; // Import getFunctions

const firebaseConfig = {
  apiKey: "AIzaSyCF6xlgRLkmdk7h_I0okCczWMaKivYZWEY",
  authDomain: "nextgen-react-app-439601.firebaseapp.com",
  projectId: "nextgen-react-app-439601",
  storageBucket: "nextgen-react-app-439601.appspot.com",
  messagingSenderId: "1009422792820",
  appId: "1:1009422792820:web:aac916beead190f4a6b921",
  measurementId: "G-K98PK89XWS"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const functions = getFunctions(app); // Initialize functions

export { auth, db, analytics, functions };
