import React from 'react';
import {
  Box,
  Typography,
  Container,
  Fade,
  Button,
  Link,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import EmailIcon from '@mui/icons-material/Email';

// Custom Theme
const theme = createTheme({
  typography: {
    fontFamily: '"Barlow", sans-serif',
    h3: {
      fontWeight: 700,
      fontSize: '3rem',
      lineHeight: 1.2,
      color: 'rgb(28, 37, 46)',
      textAlign: 'center',
      marginBottom: '1rem',
      '@media (max-width:600px)': {
        fontSize: '2.5rem',
      },
    },
    body1: {
      fontSize: '1.125rem',
      color: 'rgba(28, 37, 46, 0.9)',
      textAlign: 'center',
      maxWidth: '600px',
      margin: '0 auto',
      marginBottom: '2rem',
      lineHeight: 1.6,
    },
    button: {
      fontSize: '1rem',
      fontWeight: 600,
    },
  },
  palette: {
    background: {
      default: '#f5f5f5',
    },
    text: {
      primary: '#1c252e',
    },
    primary: {
      main: '#1976d2',
      contrastText: '#ffffff',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          transition: 'background-color 0.3s ease',
          '&:hover': {
            backgroundColor: '#1565c0',
          },
        },
      },
    },
  },
});

const Contact = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          minHeight: '100vh',
          bgcolor: 'background.default',
          padding: { xs: 2, sm: 4, md: 6 },
        }}
      >
        <Container maxWidth="sm">
          <Fade in timeout={1000}>
            <Typography variant="h3" component="h1">
              Contact Us
            </Typography>
          </Fade>
          <Fade in timeout={1500}>
            <Typography variant="body1">
              Have questions or need assistance? We're here to help! Reach out to us using the information below.
            </Typography>
          </Fade>
          <Fade in timeout={2000}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography variant="body1" sx={{ mb: 2 }}>
                Email us at:{' '}
                <Link
                  href="mailto:smarterleadfinderhelp@gmail.com"
                  underline="none"
                  sx={{ fontWeight: 600, color: 'primary.main' }}
                >
                  smarterleadfinderhelp@gmail.com
                </Link>
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<EmailIcon />}
                href="mailto:smarterleadfinderhelp@gmail.com"
                aria-label="Send an email"
                sx={{
                  textTransform: 'none',
                  paddingX: { xs: 3, sm: 4 },
                  paddingY: { xs: 1, sm: 1.5 },
                  fontSize: '1rem',
                }}
              >
                Send Email
              </Button>
            </Box>
          </Fade>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Contact;
