// src/components/ProfileMenu.js

import React, { useState, useEffect, useRef, useContext } from 'react';
import { IconButton, Snackbar, Alert } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ExitToAppIcon from '@mui/icons-material/ExitToApp'; // Icon for Logout
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebaseConfig';
import { UserContext } from '../UserContext';
import PropTypes from 'prop-types';

const ProfileMenu = ({ onLogout, navbarType }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef();
  const { isPremium } = useContext(UserContext);
  const [error, setError] = useState(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  // Function to handle 'Manage Subscription'
  const handleManageSubscription = async () => {
    handleCloseMenu();
    try {
      if (!isPremium) {
        setError('You do not have an active subscription.');
        return;
      }
      const createPortalLink = httpsCallable(
        functions,
        'ext-firestore-stripe-payments-createPortalLink'
      );
      const { data } = await createPortalLink({
        returnUrl: window.location.origin,
      });
      window.location.assign(data.url);
    } catch (error) {
      console.error('Error creating portal link:', error);
      setError(
        'An error occurred while redirecting to the subscription portal.'
      );
    }
  };

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    if (menuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen]);

  // Determine if the navbarType is mobile
  const isMobile = navbarType === 'mobile';

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      {/* Profile Icon */}
      <IconButton
        size="large"
        edge="end"
        color="primary"
        aria-label="account of current user"
        onClick={toggleMenu}
        sx={{ marginLeft: isMobile ? 0 : 2 }} // Remove marginLeft for mobile
      >
        <AccountCircle />
      </IconButton>

      {/* Custom Profile Dropdown Menu */}
      {menuOpen && (
        <div
          ref={menuRef}
          style={{
            position: 'absolute',
            top: '100%',
            right: '0', // Always align to the right
            marginTop: '8px',
            backgroundColor: 'white',
            color: 'rgb(28, 37, 46)',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
            zIndex: 1000,
            minWidth: '200px',
            padding: '8px 0',
            // Additional shift for mobile
            transform: isMobile
              ? 'translate(180px, -10px)' // Shift right by 180px and up by 10px
              : 'none',
          }}
        >
          {/* 'Manage Subscription' Option */}
          <button
            onClick={handleManageSubscription}
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '10px 16px',
              width: '100%',
              background: 'none',
              border: 'none',
              textAlign: 'left',
              fontSize: '14px',
              lineHeight: '24px',
              cursor: 'pointer',
              color: 'rgb(28, 37, 46)',
            }}
          >
            <ManageAccountsIcon
              fontSize="small"
              style={{ marginRight: '10px' }}
            />
            Manage Subscription
          </button>
          {/* Logout Option */}
          <button
            onClick={() => {
              handleCloseMenu();
              if (onLogout) {
                onLogout();
              }
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '10px 16px',
              width: '100%',
              background: 'none',
              border: 'none',
              textAlign: 'left',
              fontSize: '14px',
              lineHeight: '24px',
              cursor: 'pointer',
              color: 'rgb(28, 37, 46)',
            }}
          >
            <ExitToAppIcon
              fontSize="small"
              style={{ marginRight: '10px' }}
            />
            Logout
          </button>
        </div>
      )}

      {/* Error Snackbar */}
      <Snackbar
        open={Boolean(error)}
        autoHideDuration={6000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleErrorClose}
          severity="error"
          sx={{ width: '100%' }}
        >
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

// Define prop types for better type checking
ProfileMenu.propTypes = {
  onLogout: PropTypes.func,
  navbarType: PropTypes.oneOf(['desktop', 'mobile']).isRequired,
};

export default ProfileMenu;
