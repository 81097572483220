// src/pages/Cancel/Cancel.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Container, Button } from '@mui/material';

const Cancel = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="sm">
      <Box sx={{ minHeight: '700px', textAlign: 'center', mt: 10 }}>
        <Typography variant="h4" gutterBottom>
          Purchase Canceled
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ mb: 3 }}>
          Your subscription purchase process was canceled. If this was a mistake, you can try again below.
        </Typography>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => navigate('/premium')}
        >
          Retry Purchase
        </Button>
      </Box>
    </Container>
  );
};

export default Cancel;
