// src/pages/SignIn/ForgotPassword.js

import React, { useState } from 'react';
import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  Link,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { auth } from '../../firebaseConfig';
import { sendPasswordResetEmail } from 'firebase/auth';
import { Link as RouterLink } from 'react-router-dom';

const theme = createTheme({
  typography: {
    fontFamily: '"Barlow", sans-serif',
    h5: {
      fontWeight: 800,
      fontSize: '32px',
      lineHeight: '40px',
      color: 'rgb(28, 37, 46)',
      textAlign: 'center',
      marginBottom: '8px',
    },
    body2: {
      fontSize: '1rem',
      color: 'rgba(28, 37, 46, 0.6)',
      textAlign: 'center',
      marginBottom: '32px',
    },
  },
});

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(''); // Success message
  const [error, setError] = useState(''); // Error message

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset email sent! Please check your inbox.');
    } catch (error) {
      console.error('Error sending reset email:', error.message);
      // Customize error messages based on error codes if needed
      setError('Failed to send reset email. Please ensure the email is correct and try again.');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xs">
        <Box sx={{ mt: 8, mb: 32 }}>
          <Typography variant="h5">Reset Your Password</Typography>
          <Typography variant="body2">
            Enter your email address below and we'll send you a link to reset your password.
          </Typography>
          {message && (
            <Typography color="success.main" variant="body2" align="center" sx={{ mt: 2 }}>
              {message}
            </Typography>
          )}
          {error && (
            <Typography color="error" variant="body2" align="center" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
          <form onSubmit={handleResetPassword} noValidate>
            <TextField
              label="Email address"
              name="email"
              fullWidth
              margin="normal"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Button
              variant="contained"
              color="inherit"
              type="submit"
              fullWidth
              sx={{ mt: 3 }}
            >
              Send Reset Email
            </Button>
          </form>
          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <Link component={RouterLink} to="/signin" underline="hover" variant="body2">
              Back to Sign In
            </Link>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default ForgotPassword;
