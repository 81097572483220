// src/routes/PremiumRoute.js

import React, { useContext } from 'react';
import { UserContext } from '../UserContext';
import Leads from '../pages/Leads/Leads';
import BuyPremium from '../pages/BuyPremium/BuyPremium';
import { CircularProgress, Box } from '@mui/material';

const PremiumRoute = ({ setIsFooterVisible }) => {
  const { user, isPremium } = useContext(UserContext);

  // Log the user and premium status to debug the current state

  if (user === undefined) {
    // Render a loading spinner while user data is being fetched
    return (
      <Box
        sx={{
          minHeight: '80vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (user && isPremium) {
    // Render Leads if user has premium access
    return <Leads isPremium={true} setIsFooterVisible={setIsFooterVisible} />;
  } else {
    // Render BuyPremium if user does not have premium access
    return <BuyPremium />;
  }
};

export default PremiumRoute;
