// src/components/Footer.js

import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Link,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

// Create a custom theme to match the NavBar's theme
const customFooterTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#f9f9f9',
      paper: '#f9f9f9',
    },
    primary: {
      main: 'rgb(28, 37, 46)',
    },
    secondary: {
      main: '#f5f5f5',
    },
    text: {
      primary: 'rgb(28, 37, 46)',
      secondary: 'rgba(28, 37, 46, 0.6)',
    },
  },
  typography: {
    fontFamily:
      '"Public Sans Thin", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    button: {
      fontWeight: 500,
      fontSize: '14px',
      textTransform: 'none',
    },
    h6: {
      fontSize: '1.1rem',
      fontWeight: 500,
      color: 'rgb(28, 37, 46)',
    },
  },
});

export default function Footer() {
  return (
    <ThemeProvider theme={customFooterTheme}>
      <Box
        component="footer"
        sx={{
          backgroundColor: 'background.paper',
          color: 'text.primary',
          paddingX: 3,
          paddingY: 4,
          borderTop: '1px solid #e0e0e0',
        }}
      >
        <Grid
          container
          spacing={4}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          {/* About Us Section */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              About Us
            </Typography>
            <Typography variant="body2" color="text.secondary">
              We are committed to fostering innovation<br />
              and integrity in everything we do.<br />
              Our mission is to streamline the connection between<br />
              professionals and opportunities.
            </Typography>
          </Grid>

          {/* Quick Links Section */}
          <Grid item xs={12} sm={2}>
            <Typography variant="h6" gutterBottom>
              Quick Links
            </Typography>
            <Box>
              <Link component={RouterLink} to="/home" color="inherit" underline="none">
                <Typography variant="body2" sx={{ marginBottom: 0.5 }}>Home</Typography>
              </Link>
              <Link component={RouterLink} to="/about" color="inherit" underline="none">
                <Typography variant="body2" sx={{ marginBottom: 0.5 }}>About</Typography>
              </Link>
              <Link component={RouterLink} to="/contact" color="inherit" underline="none">
                <Typography variant="body2" sx={{ marginBottom: 0.5 }}>Contact</Typography>
              </Link>
            </Box>
          </Grid>

          {/* Legal Section */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Legal
            </Typography>
            <Box>
              <Link component={RouterLink} to="/terms" color="inherit" underline="none">
                <Typography variant="body2" sx={{ marginBottom: 0.5 }}>Terms of Service</Typography>
              </Link>
              <Link component={RouterLink} to="/privacy" color="inherit" underline="none">
                <Typography variant="body2" sx={{ marginBottom: 0.5 }}>Privacy Policy</Typography>
              </Link>
              <Link component={RouterLink} to="/faq" color="inherit" underline="none">
                <Typography variant="body2" sx={{ marginBottom: 0.5 }}>FAQ</Typography>
              </Link>
            </Box>
          </Grid>
        </Grid>

        {/* Bottom Bar */}
        <Box sx={{ textAlign: 'center', marginTop: 4 }}>
          <Typography variant="body2" color="text.secondary">
            © {new Date().getFullYear()} NextGenPrograms - All Rights Reserved
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
