// src/pages/Leads/components/SkeletonCard.js
import React from 'react';
import { Card, CardContent, CardActions, Skeleton, Box, CircularProgress } from '@mui/material';

export default function SkeletonCard() {
  return (
    <Card
      sx={{
        margin: 2,
        backgroundColor: '#FAFAFA',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        border: '1px solid #f0f0f0',
        borderRadius: '12px',
      }}
    >
      <CardContent>
        {/* Simulated Header with Author and Timestamp */}
        <Skeleton variant="text" width="40%" height={28} sx={{ mb: 1 }} />
        <Skeleton variant="text" width="60%" height={20} sx={{ mb: 1 }} />

        {/* Simulated Category Section */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 1,
            marginBottom: 2,
          }}
        >
          <Skeleton variant="circular" width={20} height={20} sx={{ marginRight: 1 }} />
          <Skeleton variant="text" width="30%" height={20} />
        </Box>

        {/* Simulated Lead Text */}
        <Skeleton variant="rectangular" height={60} sx={{ borderRadius: '4px', mb: 2 }} />
      </CardContent>

      <CardActions
        sx={{
          justifyContent: 'flex-start',
          paddingLeft: 2,
          paddingBottom: 2,
        }}
      >
        {/* Simulated Contact Button */}
        <Skeleton variant="rectangular" width={80} height={30} sx={{ borderRadius: '24px' }} />
      </CardActions>

      {/* Spinner to Indicate Active Loading */}
      <Box display="flex" justifyContent="center" paddingBottom={2}>
        <CircularProgress size={24} />
      </Box>
    </Card>
  );
}
