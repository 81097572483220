// src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import SignUp from './pages/SignUp/SignUp';
import SignIn from './pages/SignIn/SignIn';
import ForgotPassword from './pages/SignIn/ForgotPassword';
import Leads from './pages/Leads/Leads';
import Home from './pages/Home/Home';
import Contact from './pages/Contact/Contact';
import About from './pages/About/About';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import PrivacyPolicy from './pages/Legal/PrivacyPolicy';
import TermsOfService from './pages/Legal/TermsOfService';
import Faq from './pages/Legal/Faq';
import PremiumRoute from './routes/PremiumRoute'; // Import PremiumRoute
import Success from './pages/Success/Success'; // Import Success
import Cancel from './pages/Cancel/Cancel'; // Import Cancel
import { logEvent } from 'firebase/analytics';
import { analytics } from './firebaseConfig';
import './App.css';
import { UserProvider } from './UserContext'; // Ensure UserProvider is imported

function App() {
  const [isFooterVisible, setIsFooterVisible] = useState(true);

  useEffect(() => {
    // Log page view when component mounts
    logEvent(analytics, 'page_view');
  }, []);

  return (
    <UserProvider>
      <Router>
        {/* Place ScrollToTop here */}
        <ScrollToTop />

        <div className="App">
          {/* Navbar */}
          <div className="NavBar">
            <NavBar />
          </div>

          {/* Main Content */}
          <div className="main-content">
            <Routes>
              <Route path="/" element={<Navigate to="/home" />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/home" element={<Home />} />

              {/* Use PremiumRoute for /premium */}
              <Route path="/premium" element={<PremiumRoute setIsFooterVisible={setIsFooterVisible} />} />

              <Route path="/contact" element={<Contact />} />
              <Route path="/about" element={<About />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<TermsOfService />} />
              <Route path="/faq" element={<Faq />} />
              <Route
                path="/leads"
                element={<Leads setIsFooterVisible={setIsFooterVisible} />}
              />

              {/* Add Success and Cancel Routes */}
              <Route path="/success" element={<Success />} />
              <Route path="/cancel" element={<Cancel />} />
            </Routes>
          </div>

          {/* Footer */}
          {isFooterVisible && (
            <div className="Footer">
              <Footer />
            </div>
          )}
        </div>
      </Router>
    </UserProvider>
  );
}

export default App;
