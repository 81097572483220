// src/components/NavBar.js

import React from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import MobileNav from './MobileNav';
import DesktopNav from './DesktopNav';
import { ThemeProvider } from '@mui/material/styles';
import customNavTheme from './customNavTheme';

const NavBar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ThemeProvider theme={customNavTheme}>
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: 'white',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          paddingX: isMobile ? 1 : 3,
          minHeight: '56px',
        }}
      >
        <Toolbar
          sx={{
            minHeight: '56px !important',
            paddingY: 0,
          }}
        >
          {isMobile ? (
            <MobileNav />
          ) : (
            <>
              {/* Logo */}
              <Box
                component={RouterLink}
                to="/"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  textDecoration: 'none',
                  color: 'text.primary',
                  marginLeft: '139.2px',
                }}
              >
                <img src="/slf_logo.webp" alt="Logo" style={{ height: '40px' }} />
              </Box>
              {/* Navigation and Profile Menu */}
              <DesktopNav />
            </>
          )}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

export default NavBar;
