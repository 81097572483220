// src/components/DesktopNav.js

import React, { useContext } from 'react';
import { Box, Button } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { UserContext } from '../UserContext';
import ProfileMenu from './ProfileMenu';

const navItems = [
  {
    label: 'Home',
    path: '/home',
  },
  {
    label: 'Premium',
    path: '/premium',
  },
  {
    label: 'About',
    path: '/about',
  },
  {
    label: 'Contact',
    path: '/contact',
  },
];

const DesktopNav = () => {
  const { user, logout } = useContext(UserContext);
  const location = useLocation();

  const handleLogout = async () => {
    await logout();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: '99.2px',
      }}
    >
      {navItems.map((item) => (
        <Button
          key={item.label}
          component={RouterLink}
          to={item.path}
          state={{ from: location.pathname }}
          sx={{
            marginLeft: 2,
            height: '40px',
            paddingY: '8px',
            paddingX: '16px',
            display: 'flex',
            alignItems: 'center',
            color: 'rgb(28, 37, 46)',
            fontWeight: 500,
            fontSize: '14px',
            textTransform: 'none',
          }}
        >
          {item.label}
        </Button>
      ))}

      {user ? (
        <>
          {/* Profile Menu */}
          <ProfileMenu onLogout={handleLogout} navbarType="desktop" />
        </>
      ) : (
        <Button
          variant="outlined"
          component={RouterLink}
          to="/signin"
          state={{ from: location.pathname }}
          sx={{
            marginLeft: 2,
            height: '35.6px',
            paddingY: '5px',
            paddingX: '12px',
            border: '1px solid rgba(145, 158, 171, 0.32)',
            borderRadius: '8px',
            fontWeight: 700,
            fontSize: '14px',
            lineHeight: '24px',
            color: 'rgb(28, 37, 46)',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            boxShadow: 'none',
            cursor: 'pointer',
            transition: 'background-color 0.25s',
            '&:hover': {
              backgroundColor: '#e0e0e0',
              borderColor: 'rgba(145, 158, 171, 0.32)',
            },
            '&:active': {
              transform: 'scale(0.98)',
            },
          }}
        >
          Sign in
        </Button>
      )}
    </Box>
  );
};

export default DesktopNav;
