// src/theme/customNavTheme.js

import { createTheme } from '@mui/material/styles';

const customNavTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#f9f9f9',
      paper: '#f9f9f9',
    },
    primary: {
      main: 'rgb(28, 37, 46)',
    },
    secondary: {
      main: '#f5f5f5',
    },
    text: {
      primary: 'rgb(28, 37, 46)',
      secondary: 'rgba(28, 37, 46, 0.6)',
    },
  },
  typography: {
    fontFamily:
      '"Public Sans Thin", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    button: {
      fontWeight: 500,
      fontSize: '14px',
      textTransform: 'none',
    },
    h6: {
      fontSize: '1.1rem',
      fontWeight: 500,
      color: 'rgb(28, 37, 46)',
    },
  },
});

export default customNavTheme;
