// src/pages/Success/Success.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Container, Button } from '@mui/material';

const Success = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="sm">
      <Box sx={{ minHeight: '700px', textAlign: 'center', mt: 10 }}>
        <Typography variant="h4" gutterBottom>
          Thank You for Upgrading!
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ mb: 3 }}>
          Your subscription to Premium has been successfully activated. You may need to{' '}
          <Typography component="span" fontWeight="bold">
            log in again
          </Typography>
          {' '}to access premium features.
        </Typography>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => navigate('/premium')}
        >
          Go to Premium
        </Button>
      </Box>
    </Container>
  );
};

export default Success;
