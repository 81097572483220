import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  Link,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { auth } from '../../firebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate, useLocation, Link as RouterLink } from 'react-router-dom';

const theme = createTheme({
  typography: {
    fontFamily: '"Barlow", sans-serif',
    h5: {
      fontWeight: 800,
      fontSize: '32px',
      lineHeight: '40px',
      color: 'rgb(28, 37, 46)',
      textAlign: 'center',
      marginBottom: '8px',
    },
    body2: {
      fontSize: '1rem',
      color: 'rgba(28, 37, 46, 0.6)',
      textAlign: 'center',
      marginBottom: '32px',
    },
  },
});

const SignIn = () => {
  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
    showPassword: false,
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true); // New loading state

  const navigate = useNavigate();
  const location = useLocation();

  const from = (location.state?.from && 
    location.state.from !== '/signup' && 
    location.state.from !== '/forgot-password' && 
    location.state.from !== '/signin') 
    ? location.state.from 
    : '/home';

  const handleChange = (prop) => (event) => {
    setFormValues({ ...formValues, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setFormValues({
      ...formValues,
      showPassword: !formValues.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true); // Start loading on form submit

    try {
      await signInWithEmailAndPassword(auth, formValues.email, formValues.password);
      navigate(from || '/home', { replace: true });
    } catch (error) {
      console.error('Error signing in:', error.message);
      setError('Invalid email or password. Please try again.');
      setLoading(false); // Stop loading if there's an error
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        navigate(from, { replace: true });
      } else {
        setLoading(false); // Stop loading if no redirect is needed
      }
    });

    return () => unsubscribe();
  }, [navigate, from]);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xs">
        {loading ? (
          <Box sx={{ minHeight: '500px', display: 'flex', justifyContent: 'center', mt: 8 }}>
            <CircularProgress /> {/* Spinner */}
          </Box>
        ) : (
          <Box sx={{ mt: 8, mb: 32 }}>
            <Typography variant="h5">Sign in to your account</Typography>
            <Typography variant="body2">
              Don’t have an account?{' '}
              <Link component={RouterLink} to="/signup" underline="hover">
                Get started
              </Link>
            </Typography>
            {error && (
              <Typography color="error" variant="body2" align="center" sx={{ mt: 1 }}>
                {error}
              </Typography>
            )}
            <form onSubmit={handleSignIn} noValidate>
              <TextField
                label="Email address"
                name="email"
                fullWidth
                margin="normal"
                variant="outlined"
                value={formValues.email}
                onChange={handleChange('email')}
                required
              />
              <Box
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <Link component={RouterLink} to="/forgot-password" underline="hover" variant="body2">
                  Forgot password?
                </Link>
              </Box>
              <FormControl variant="outlined" fullWidth margin="normal" required>
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={formValues.showPassword ? 'text' : 'password'}
                  value={formValues.password}
                  onChange={handleChange('password')}
                  label="Password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {formValues.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Button
                variant="contained"
                color="inherit"
                type="submit"
                fullWidth
                sx={{ mt: 3 }}
              >
                Sign in
              </Button>
            </form>
          </Box>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default SignIn;
