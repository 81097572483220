// src/pages/Leads/components/FilterSection.js

import React, { useState } from 'react';
import {
  Box,
  Grid,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
  Divider,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from '@mui/material';

export default function FilterSection({
  open,
  onClose,
  handleSearch,
}) {
  const [localFilters, setLocalFilters] = useState({
    searchText: '',
    selectedCategory: [],
    dateRange: [null, null],
  });

  // Set fullScreen to false to prevent the dialog from covering the whole screen on mobile
  const fullScreen = false;

  const classifications = {
    0: 'All',
    1: 'Handyman',
    2: 'Plumber',
    3: 'Electrician',
    4: 'Landscaper',
    5: 'HVAC Technician',
    6: 'Roofer',
    7: 'Painter',
    8: 'Pest Control',
    9: 'General Contractor',
    10: 'Gutter Cleaning',
    11: 'Housekeeper',
    12: 'Carpenter'
  };

  const handleCategoryToggle = (categoryId) => {
    setLocalFilters((prevFilters) => {
      let newSelectedCategory = [...prevFilters.selectedCategory];
      if (categoryId === 0) {
        // 'All' category
        if (newSelectedCategory.includes(0)) {
          // 'All' is being unchecked
          newSelectedCategory = [];
        } else {
          // 'All' is being checked
          newSelectedCategory = [0];
        }
      } else {
        // Other categories
        if (newSelectedCategory.includes(categoryId)) {
          // Category is being unchecked
          newSelectedCategory = newSelectedCategory.filter((id) => id !== categoryId);
        } else {
          newSelectedCategory.push(categoryId);
        }
        // If 'All' is selected, remove it
        newSelectedCategory = newSelectedCategory.filter((id) => id !== 0);
      }
      return { ...prevFilters, selectedCategory: newSelectedCategory };
    });
  };

  const handleResetFilters = () => {
    setLocalFilters({
      searchText: '',
      selectedCategory: [],
      dateRange: [null, null],
    });
  };

  const isResetDisabled =
    localFilters.selectedCategory.length === 0 &&
    localFilters.searchText.trim() === '' &&
    localFilters.dateRange.every((date) => date === null);

  const handleSearchClick = () => {
    handleSearch(localFilters);
    onClose();
  };

  const isSearchDisabled = localFilters.selectedCategory.length === 0;

  // Sort categories alphabetically by their names
  const sortedCategories = Object.entries(classifications).sort(([, a], [, b]) =>
    a.localeCompare(b)
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      maxWidth="sm"
      fullWidth
      disableScrollLock={true} // Added this line
      PaperProps={{
        sx: {
          borderRadius: 2,
          overflow: 'visible',
        },
      }}
      BackdropProps={{
        sx: {
          backdropFilter: 'blur(5px)',
          backgroundColor: 'rgba(0,0,0,0.3)',
        },
      }}
    >
      <DialogTitle sx={{ fontWeight: 'bold', textAlign: 'center' }}>
        Search Leads
      </DialogTitle>
      <DialogContent>
        <Box sx={{ padding: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
          {/* Categories Section */}
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', fontSize: 13, mb: 1 }}>
            Categories (Required)
          </Typography>
          <Grid container spacing={0.5} sx={{ rowGap: 0.5 }}>
            {sortedCategories.map(([id, category]) => (
              <Grid item xs={6} sm={4} key={id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={localFilters.selectedCategory.includes(Number(id))}
                      onChange={() => handleCategoryToggle(Number(id))}
                      disabled={
                        localFilters.selectedCategory.includes(0) && Number(id) !== 0
                      }
                      size="small"
                      sx={{ padding: 0.3 }}
                    />
                  }
                  label={
                    <Typography variant="body2" sx={{ fontSize: 11 }}>
                      {category}
                    </Typography>
                  }
                  sx={{ margin: 0, height: 32 }}
                />
              </Grid>
            ))}
          </Grid>

          <Divider sx={{ marginY: 1 }} />

          {/* The following sections have been removed:
              - Date Pickers
              - Search Text Field
              They are kept in the state but not rendered in the UI.
          */}
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button
          variant="text"
          onClick={handleResetFilters}
          disabled={isResetDisabled}
          sx={{
            color: isResetDisabled ? 'text.secondary' : 'black',
            textTransform: 'none',
            fontSize: 12,
            minWidth: 60,
          }}
        >
          Clear
        </Button>
        <Button
          variant="contained"
          onClick={handleSearchClick}
          disabled={isSearchDisabled}
          sx={{
            textTransform: 'none',
            fontSize: 12,
            minWidth: 60,
          }}
        >
          Search
        </Button>
      </DialogActions>
    </Dialog>
  );
}
