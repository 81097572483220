import React from 'react';
import { Backdrop, Box } from '@mui/material';
import { keyframes } from '@mui/system';

// Keyframes for continuous pixel movement
const move = keyframes`
  0% {
    transform: translate(0, 0);
    opacity: 0.6;
  }
  25% {
    transform: translate(-10px, 10px);
    opacity: 0.8;
  }
  50% {
    transform: translate(10px, -10px);
    opacity: 1;
  }
  75% {
    transform: translate(-5px, -5px);
    opacity: 0.7;
  }
  100% {
    transform: translate(5px, 5px);
    opacity: 0.6;
  }
`;

// Generate dynamic pixel blocks
const generatePixels = (count) => {
  const pixels = [];
  for (let i = 0; i < count; i++) {
    const size = Math.floor(Math.random() * 6) + 5; // Size between 5px and 10px
    const left = Math.random() * 100; // Random position
    const top = Math.random() * 100; // Random position
    const duration = Math.random() * 2 + 1; // Duration between 1s and 3s
    const delay = Math.random() * 1; // Delay up to 1s

    pixels.push(
      <Box
        key={i}
        sx={{
          position: 'absolute',
          top: `${top}%`,
          left: `${left}%`,
          width: `${size}px`,
          height: `${size}px`,
          bgcolor: 'rgba(100, 100, 100, 0.7)', // Medium gray for balanced visibility
          animation: `${move} ${duration}s infinite ${delay}s`,
          borderRadius: '1px', // Subtle rounding for a modern look
        }}
      />
    );
  }
  return pixels;
};

// PageLoader Component
const PageLoader = ({ open }) => {
  const pixelCount = 200; // Number of pixels for more activity
  const pixelElements = generatePixels(pixelCount);

  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backdropFilter: 'blur(1px)', // Softer blur for better page visibility
        backgroundColor: 'rgba(31, 31, 31, 0.2)', // Even lighter overlay
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
      open={open}
      role="alert"
      aria-busy={open}
    >
      {/* Dynamic pixelated effect */}
      <Box sx={{ position: 'absolute', width: '100%', height: '100%', pointerEvents: 'none' }}>
        {pixelElements}
      </Box>
    </Backdrop>
  );
};

export default PageLoader;
