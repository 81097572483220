// src/components/DrawerContent.js

import React, { useContext } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import StarIcon from '@mui/icons-material/Star';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import InfoIcon from '@mui/icons-material/Info';
import LoginIcon from '@mui/icons-material/Login';
import ProfileMenu from './ProfileMenu';
import { UserContext } from '../UserContext';

const navItems = [
  {
    label: 'Home',
    path: '/home',
    icon: <HomeIcon />,
  },
  {
    label: 'Premium',
    path: '/premium',
    icon: <StarIcon />,
  },
  {
    label: 'About',
    path: '/about',
    icon: <InfoIcon />,
  },
  {
    label: 'Contact',
    path: '/contact',
    icon: <ContactMailIcon />,
  },
];

const DrawerContent = ({ toggleDrawer }) => {
  const { user, logout } = useContext(UserContext);
  const location = useLocation();

  const handleLogout = async () => {
    await logout();
  };

  // Function to close the drawer
  const handleClose = () => {
    toggleDrawer(false)();
  };

  return (
    <Box
      sx={{
        width: 250,
        bgcolor: 'background.paper',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
      role="presentation"
    >
      {/* Top Section: Logo and Navigation */}
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginY: 2,
          }}
        >
          <RouterLink to="/" style={{ textDecoration: 'none' }}>
            <img src="/slf_logo.webp" alt="Logo" style={{ height: '40px' }} />
          </RouterLink>
        </Box>
        <Divider />
        <List>
          {navItems.map((item) => (
            <ListItem key={item.label} disablePadding>
              <ListItemButton
                component={RouterLink}
                to={item.path}
                state={{ from: location.pathname }}
                onClick={handleClose}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingY: '8px',
                }}
              >
                <ListItemIcon sx={{ color: 'inherit', minWidth: '40px' }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>

      {/* Divider between Navigation and Profile */}
      <Divider />

      {/* Profile Section */}
      <Box sx={{ flexGrow: 1 }}>
        <List>
          <ListItem disablePadding>
            {user ? (
              <ProfileMenu onLogout={handleLogout} navbarType="mobile" />
            ) : (
              <ListItemButton
                component={RouterLink}
                to="/signin"
                state={{ from: location.pathname }}
                onClick={handleClose}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingY: '8px',
                }}
              >
                <ListItemIcon sx={{ color: 'inherit', minWidth: '40px' }}>
                  <LoginIcon />
                </ListItemIcon>
                <ListItemText primary="Sign In" />
              </ListItemButton>
            )}
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

export default DrawerContent;
