import React, { createContext, useState, useEffect } from 'react';
import { auth, db } from './firebaseConfig';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(undefined);
  const [isPremium, setIsPremium] = useState(false);

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        const userDocRef = doc(db, 'users', currentUser.uid);
  
        // Listen to Firestore changes in the user's document
        const unsubscribeUser = onSnapshot(userDocRef, (docSnap) => {
          if (docSnap.exists()) {
            const userData = {
              uid: currentUser.uid,
              email: currentUser.email,
              ...docSnap.data(),
            };
            setUser(userData);
  
            // Set isPremium based directly on Firestore `premium` field
            setIsPremium(userData.premium === true);
          } else {
            // Handle case where user data is missing
            setUser(null);
            setIsPremium(false);
          }
        });
  
        return () => {
          unsubscribeUser();
        };
      } else {
        setUser(null);
        setIsPremium(false);
      }
    });
  
    return () => {
      unsubscribeAuth();
    };
  }, []);  

  const logout = async () => {
    await signOut(auth);
    setUser(null);
    setIsPremium(false);
  };

  return (
    <UserContext.Provider value={{ user, isPremium, logout }}>
      {children}
    </UserContext.Provider>
  );
};
