import React, { useContext, useState } from 'react';
import {
  Box,
  Typography,
  Container,
  Fade,
  Button,
  Grid,
  Card,
  CardContent,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { UserContext } from '../../UserContext';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebaseConfig';
import { collection, addDoc, onSnapshot } from 'firebase/firestore';
import { loadStripe } from '@stripe/stripe-js';

// Initialize Stripe with your Publishable Key
const stripePromise = loadStripe('pk_live_51QKr96AhMQKtAs5VuciZYllj6Z9EGhA8rgFrtkho6Z5VMtl5zga4UnD0RmS63SZCIaqCKAFvfD5kLV1SvtExIBuW00AvyKyAty');

// Custom Theme
const theme = createTheme({
  typography: {
    fontFamily: '"Barlow", sans-serif',
    h3: {
      fontWeight: 800,
      fontSize: '48px',
      lineHeight: '56px',
      color: 'rgb(28, 37, 46)',
      textAlign: 'center',
      '@media (max-width:600px)': {
        fontSize: '36px',
        lineHeight: '44px',
      },
    },
    body1: {
      fontSize: '1.125rem',
      color: 'rgba(28, 37, 46, 0.8)',
      textAlign: 'center',
      maxWidth: '700px',
      margin: '0 auto',
    },
  },
  palette: {
    background: {
      default: '#f9f9f9',
    },
    text: {
      primary: '#333333',
    },
    primary: {
      main: '#1976d2',
    },
  },
});

const BuyPremium = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // State for loading spinner
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const setSnackbarMessage = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleUpgrade = async () => {
    if (!user) {
      setSnackbarMessage('Please sign in to upgrade to premium.', 'warning');
      navigate('/signin', { state: { from: '/premium' } });
      return;
    }

    setLoading(true); // Show loading spinner

    try {
      const checkoutSessionsRef = collection(db, 'customers', user.uid, 'checkout_sessions');
      const docRef = await addDoc(checkoutSessionsRef, {
        price: 'price_1QKuElAhMQKtAs5Va384DYJ9', // Test Price ID
        success_url: 'https://smarterleadfinder.com/success?session_id={CHECKOUT_SESSION_ID}',
        cancel_url: 'https://smarterleadfinder.com/cancel',
      });

      const unsubscribe = onSnapshot(docRef, async (snapshot) => {
        const data = snapshot.data();
        if (data) {
          const { error, url, sessionId } = data;
          if (error) {
            setSnackbarMessage(error.message || 'There was an error upgrading your account.', 'error');
            setLoading(false); // Hide loading spinner
          } else if (url && sessionId) {
            const stripe = await stripePromise;
            const { error: stripeError } = await stripe.redirectToCheckout({ sessionId });
            if (stripeError) {
              setSnackbarMessage(stripeError.message || 'There was an error redirecting to Stripe.', 'error');
            }
            unsubscribe();
            setLoading(false); // Hide loading spinner after redirect
          } else {
            console.warn("Missing sessionId or URL in Firestore data:", data);
          }
        }
      });
    } catch (error) {
      console.error('Error upgrading to premium:', error);
      setSnackbarMessage('There was an error upgrading your account. Please try again.', 'error');
      setLoading(false); // Hide loading spinner on error
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          minHeight: '80vh',
          display: 'flex',
          alignItems: 'center',
          bgcolor: 'background.default',
          padding: 4,
          paddingBottom: 8,
        }}
      >
        <Container maxWidth="md">
          <Fade in={true} timeout={1000}>
            <Typography variant="h3" gutterBottom>
              Upgrade to Premium Leads
            </Typography>
          </Fade>
          <Fade in={true} timeout={1500}>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Unlock immediate access to the freshest leads and gain a competitive edge.
            </Typography>
          </Fade>
          <Fade in={true} timeout={2000}>
            <Grid container spacing={4} sx={{ mt: 4 }}>
              <Grid item xs={12} sm={6}>
                <Card
                  variant="outlined"
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <CardContent>
                    <Typography variant="h5" gutterBottom>
                      Premium Benefits
                    </Typography>
                    <Typography variant="body2" color="text.secondary" style={{ lineHeight: 1.8 }}>
                      • <strong>Real-time access</strong> to the latest leads<br />
                      • <strong>Priority support</strong> for customers<br />
                      • <strong>No ads</strong>, just a seamless experience<br />
                      • <strong>Cancel anytime</strong> with no hidden fees
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card
                  variant="outlined"
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <CardContent sx={{ textAlign: 'center' }}>
                    <Typography variant="h5" gutterBottom>
                      Pricing
                    </Typography>
                    <Typography variant="h4" color="primary">
                      $4.99/month
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mt: 4 }}
                      onClick={handleUpgrade}
                      disabled={loading} // Disable button while loading
                    >
                      {loading ? <CircularProgress size={24} color="inherit" /> : 'Upgrade Now'}
                    </Button>
                    <Typography
                      variant="body2"
                      gutterBottom
                      sx={{
                        fontSize: '0.9rem', // Adjust font size
                        marginTop: 2,       // Add margin at the top (theme.spacing(2))
                        lineHeight: 1.5,    // Improve line spacing for readability
                      }}
                    >
                      Secure Payment: Powered by Stripe. <br />
                      Cancel anytime with no hidden fees.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Fade>
        </Container>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
};

export default BuyPremium;
